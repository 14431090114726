import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

interface BoxboxProps {
  year: string;
  subHeaders: string[] | null;
  textMessages: string[];
}

const Boxbox: React.FC<BoxboxProps> = ({ year, subHeaders, textMessages }) => {
  const [localSubHeaders, setLocalSubHeaders] = useState<string[]>([]);
  const [localTextMessages, setLocalTextMessages] = useState<string[]>([]);

  useEffect(() => {
    //setLocalSubHeaders(['Solutions made simple.']);
    //setLocalTextMessages(['Combining hi-fidelity design prototypes, system architecture '
    //  + 'and requirements engineering to create software solutions.']);
  }, []);

  const renderSubHeaders = (subHeadersList: string[] | undefined) => {
    return (subHeadersList || localSubHeaders).map((subHeader, index) => (
      <div key={index}>
        <Typography id={`subHeaderText-${index}`} variant="h6" component="p" sx={{ marginTop: '5vh' }}>
          {subHeader}
        </Typography>
        <Typography
          id={`sidebarText-${index}`}
          component="p"
          sx={{ whiteSpace: 'pre-line', lineHeight: '2.0', margin: '20px 0', overflowWrap: 'break-word', fontSize: '1.0rem' }}
          dangerouslySetInnerHTML={{ __html: (textMessages[index] || localTextMessages[index]) }} // Render HTML safely
        />
      </div>
    ));
  };

  return (
    <Box
      id="boxbox"
      sx={{
        padding: 4,
        bgcolor: 'rgba(255, 255, 255, 0.6)',
        borderRadius: 2,
        boxShadow: 3,
        minWidth: { xs: '95vw', sm: '30vw' },
        maxWidth: { xs: '95vw', sm: '30vw' },
        maxHeight: {xs: '80vh', sm: '93vh'},
        paddingTop: {xs: '2vh'},
        marginTop: {xs: '2vh'},
        marginLeft: {xs:'2vw', sm: 0},
        overflowY: 'auto'
      }}
    >
      <Typography
        id="sidebarHeaderText"
        variant="h5"
        component="h2"
        sx={{ marginTop: '5vh', justifyContent: 'center', textAlign: 'center' }}
      >
        {year}
      </Typography>
      <hr />
      {renderSubHeaders(subHeaders ?? undefined)}
    </Box>
  );
};

export default Boxbox;
