import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import App from './App';

// Create a root.
const container = document.getElementById('root');

// Ensure the container exists before creating the root
if (container) {
  const root = ReactDOM.createRoot(container);

  // Initial render
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
