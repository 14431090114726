import {IconCloud} from "./magicui/icon-cloud";

const slugs = [
  "typescript",
  "javascript",
  "java",
  "react",
  "angular",
  "css3",
  "nodedotjs",
  "nextdotjs",
  "amazonaws",
  "postgresql",
  "docker",
  "git",
  "jira",
  "github",
  "gitlab",
  "visualstudiocode",
  "figma",
  "dotnet",
  "python",
];

export function IconCloudDemo() {
  return (
      <IconCloud iconSlugs={slugs} />
  );
}
