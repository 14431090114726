import React, { useState, useRef } from 'react';
import '../App.css';

interface ArrowLineProps {
  onCircleClick: (year: string, description: string) => void; // Update the type to accept both year and description
}

const ArrowLine: React.FC<ArrowLineProps> = ({ onCircleClick }) => {
  const events = [
    { year: "2019", description: "Worked with MEA Mobile Ltd for University project \n Scholarship on Māori Corpus of Tweets for Ngā Pae o Te Māramatanga" },
    { year: "2020", description: "GGJ event \n Honours of BCMS for Huri Whakatau" },
    { year: "2021", description: "Completed BCMS \n Begin Masters Project \n Podcast for Huri Whakatau Project \n Featured in Engineering NZ article" },
    { year: "2022", description: "Acknowledgement for work during Māori Corpus of Tweets \n Employed at Tidy International \n Employed at National Hauora Coalition \n Development on Poker Project" },
    { year: "2023", description: "Graduated University of Waikato with Masters" },
    { year: "2024", description: "Launched Lightsorctechnologies.com \n Worked on new framework for Mōhio Statusboards using React" },
  ];

  const [hoveredYear, setHoveredYear] = useState<string | null>(null);
  const [relativeMouseX, setRelativeMouseX] = useState<number | null>(null);
  const lineRef = useRef<HTMLDivElement | null>(null);

  // Calculate the percentage of time elapsed from January 1, 2019 to today
  const startDate = new Date('2019-01-01');
  const currentYear = new Date().getFullYear();
  const endDate = new Date(currentYear, 11, 31);
  const today = new Date();

  const totalDuration = endDate.getTime() - startDate.getTime();
  const elapsedDuration = today.getTime() - startDate.getTime();
  const percentage = Math.min((elapsedDuration / totalDuration) * 95, 95); // Cap at 95%

  const getGradientBackground = (relativeX: number | null) => {
    const totalCircles = events.length;
    if (relativeX !== null && lineRef.current) {
      const lineWidth = lineRef.current.clientWidth; // Get the width of the line
      const circlePercentage = (relativeX / lineWidth) * 100; // Calculate percentage based on mouse position

      const buffer = 1; // Adjust the buffer if needed for smoother transitions
      const adjustedCirclePercentage = Math.max(circlePercentage - buffer, 0);
      const adjustedPercentage = Math.min(percentage, 95);

      return `linear-gradient(to right, #FFF 0%, #FFF ${adjustedCirclePercentage}%, #eb884c ${adjustedCirclePercentage}%, #eb884c ${adjustedPercentage}%, #535353 ${adjustedPercentage}%, #535353 100%)`;
    }
    return `linear-gradient(to right, #eb884c ${percentage}%, #535353 ${percentage}%)`;
  };

  const handleMouseEnter = (year: string) => {
    setHoveredYear(year);
  };

  const handleMouseLeave = () => {
    setHoveredYear(null);
    setRelativeMouseX(null); // Reset mouse position on leave
  };
  const gradientBackground = getGradientBackground(relativeMouseX);

  // Get the X position of the mouse relative to the circle area
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (lineRef.current) {
      const lineRect = lineRef.current.getBoundingClientRect(); // Get line's position
      const lineLeft = lineRect.left; // X position of the left of the line
      const containerRect = e.currentTarget.getBoundingClientRect(); // Get the container's position
      const containerLeft = containerRect.left; // X position of the left of the container
      const containerRight = containerRect.right; // X position of the right of the container
      const containerWidth = containerRight - containerLeft; // Width of the container
      const centerPosition = containerLeft + containerWidth / 2; // Center position of the container

      const relativeX = centerPosition - lineLeft; // Calculate relative X position

      setRelativeMouseX(relativeX); // Update state with relative position
    }
  };

  const handleCircleClick = (year: string, description: string) => {
    onCircleClick(year, description); // Pass both year and description to the parent
  };

  return (
    <div id="line" className="line-container">
      <div
        className="line"
        ref={lineRef}
        style={{ background: gradientBackground, transition: 'background 15s ease' }}
      >
        {events.map((event, index) => (
          <div
            className="circle-container clickable"
            key={index}
            onMouseEnter={() => handleMouseEnter(event.year)}
            onMouseLeave={handleMouseLeave} 
            onMouseMove={handleMouseMove}
            onClick={() => handleCircleClick(event.year, event.description)} // Handle click event here
          >
            <div className="circle"></div>
            <div className="circle-text">{event.year}</div>
            <div className="description-margin">
              <div className="circle-description">
                {event.description.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArrowLine;
