import React from 'react';
import { AppBar, Toolbar, Typography, Box, Stack } from '@mui/material';

const DownloadButton = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + '/release.zip'; // The path to your zip file
    link.download = 'poker-trainer.zip'; // Optional: specify a filename
    link.click();
  };

  return (
    <Typography variant="h6" id="hiddenOnMobile" className="headerText" onClick={handleDownload}>Projects</Typography>
  );
};

export default DownloadButton;