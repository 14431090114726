import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Boxbox from './Components/Box';
import ArrowLine from './Components/ArrowLine';
import { main, skills, education, contactDetails, removeAllChildren, circleClickInformation, reviewForm } from './Interfaces/miscellaneous';
import Marquee from "react-fast-marquee";
import DownloadButton from './Components/DownloadButton';
import { IconCloudDemo } from './Components/IconCloud';

// Designs found at: https://www.figma.com/design/coo21nzyMMZcjvsxBJNEyA/Untitled?node-id=3-8&t=0lMrFd1aBiniEJen-0

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
}));

const App: React.FC = () => {
  // Use useState to manage the state
  const [year, setYear] = useState<string>('');
  const [subHeaders, setSubHeaders] = useState<string[] | null>(null);
  const [textMessages, setTextMessages] = useState<string[]>([]);

  const handleCircleClick = (year: string, headers: string) => {
    const headerStrings = headers.split('\n'); // Split headers into an array
    const infoObjects = circleClickInformation(year, headers);
    // Ensure subHeader is always defined
    const validSubHeader = headerStrings || []; // Default to empty array if null
    setSubHeaders(validSubHeader); // Pass validSubHeader here
    contentMessage(year, infoObjects, validSubHeader); // Call contentMessage with the year, header, and info
  };

  function contentMessage(headerMessage: string, textMessage: string | string[], subHeader?: string[] | null) {
    if (typeof textMessage === 'string') {
      textMessage = [textMessage];
    }
  
    // Check if subHeader is empty, null, or undefined and set it to [' '] if it is
    const normalizedSubHeader = !subHeader || subHeader.length === 0 ? [' '] : subHeader;
  
    setYear(headerMessage); // Use the setter function to update the state
    setSubHeaders(normalizedSubHeader); // Use the setter function to update the state
    setTextMessages(textMessage); // Use the setter function to update the state
  }
  

  /*
  Header items
  */
  const Main = () => {
    removeAllChildren();
    contentMessage(main.subheader, main.info, [" "]);
  };

  const Skills = () => {
    removeAllChildren();
    contentMessage(skills.subheader, skills.info);
  };

  const Education = () => {
    removeAllChildren();
    contentMessage(education.subheader, education.info);
  };

  const ContactDetails = () => {
    removeAllChildren();
    contentMessage(contactDetails.subheader, contactDetails.info);
  };

  const Reviews = () => {
    removeAllChildren();
    contentMessage(reviewForm.subheader, reviewForm.info);
  };

  useEffect(() => {
    Main(); // Properly invoke Main as a function
  }, []);

  /* 
    LINKS
  */
  const Linkedin = () => {
    window.open('https://www.linkedin.com/in/tamahau-brown-9287b7139/', '_blank'); // Open the link in a new tab
  }

  const Github = () => {
    window.open('https://github.com/TamahauBrown', '_blank'); // Open the link in a new tab
  }

  /*
   MAIN APPLICATION
  */
  return (
    <Root>
      <AppBar position="static">
        <Toolbar>
          <Title variant="h5">
            <span className='clickable' onClick={Main}>Tamahau Brown</span>
          </Title>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={3}>
            <Typography variant="h6" className="headerText" component="a" href="TamahauBrownCV.pdf" download="TamahauBrownCV.pdf" sx={{ textDecoration: 'none', color: 'inherit' }}>CV</Typography>
            <Typography variant="h6" className="headerText" onClick={Github}>Github</Typography>
            <Typography variant="h6" className="headerText" onClick={Linkedin}>Linkedin</Typography>
            <Typography variant="h6" id="hiddenOnMobile" className="headerText" onClick={ContactDetails}>Contact</Typography>
            <Typography variant="h6" id="hiddenOnMobile" className="headerText" onClick={Education}>Education</Typography>
            <Typography variant="h6" id="hiddenOnMobile" className="headerText" onClick={Skills}>Skills</Typography>
            <Typography variant="h6" id="hiddenOnMobile" className="headerText" onClick={Reviews}>Review</Typography>
            <DownloadButton/>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box id="mainScreen"
        sx={{
          backgroundImage: 'url(bluebackground.jpg)', // Path to your background image
          backgroundSize: 'cover',
          backgroundPosition: 'left top',
          backgroundRepeat: 'no-repeat',
          minWidth: '100vw',
          minHeight: '85vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch',
        }}
      >
        <Boxbox year={year} subHeaders={subHeaders} textMessages={textMessages} />
        <div id="contentContainer" className="pc-only flex flex-col">
        <IconCloudDemo/>
          <div className="marquee-container">
            <Marquee>
              <img src="PokerTrainer.png" alt="cat" className="marquee-image" />
              <img src="PokerTrainerCode.png" alt="PokerTrainerInvokeCode" className="marquee-image" />
              <img src="PokerTrainerOnSubmit.png" alt="PokerTrainerSubmission" className="marquee-image" />
            </Marquee>
          </div>
          <div>
            <ArrowLine onCircleClick={handleCircleClick} />
          </div>
        </div>

      </Box>
    </Root>
  );
}

export default App;
